var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gl-card',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('h4',{staticClass:"gl-font-lg gl-mt-0"},[_vm._v(_vm._s(_vm.$options.i18n.title))]),_vm._v(" "),_c('p',{staticClass:"gl-mb-3"},[_vm._v(_vm._s(_vm.$options.i18n.firstParagraph))]),_vm._v(" "),_c('ul',[_c('li',[_c('gl-sprintf',{attrs:{"message":_vm.$options.i18n.browseExamples},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.ciExamplesHelpPagePath,"target":"_blank"}},[_vm._v("\n              "+_vm._s(content)+"\n            ")])]}}])})],1),_vm._v(" "),_c('li',[_c('gl-sprintf',{attrs:{"message":_vm.$options.i18n.viewSyntaxRef},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.ymlHelpPagePath,"target":"_blank"}},[_vm._v("\n              "+_vm._s(content)+"\n            ")])]}}])})],1),_vm._v(" "),_c('li',[_c('gl-sprintf',{attrs:{"message":_vm.$options.i18n.learnMore},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.ciHelpPagePath,"target":"_blank"}},[_vm._v("\n              "+_vm._s(content)+"\n            ")])]}}])})],1),_vm._v(" "),_c('li',[_c('gl-sprintf',{attrs:{"message":_vm.$options.i18n.needs},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.needsHelpPagePath,"target":"_blank"}},[_vm._v("\n              "+_vm._s(content)+"\n            ")])]}}])})],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }